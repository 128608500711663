import Vue from 'vue'
import App from './App.vue'
import { i18n } from './i18n'
import router from './router'
import AOS from 'aos'
import 'aos/dist/aos.css'
import VueMeta from 'vue-meta'
import VueGtm from '@gtm-support/vue2-gtm';


import { Trans } from './plugins/Translation'
 
Vue.prototype.$i18nRoute = Trans.i18nRoute.bind(Trans)

Vue.config.productionTip = false

Vue.component("HomePage", require('./pages/HomePage/index.vue').default);
Vue.component("ContactPage", require('./pages/ContactPage/index.vue').default);
Vue.component("FountainsPage", require('./pages/FountainsPage/index.vue').default);
Vue.component("TaigaPage", require('./pages/TaigaPage/index.vue').default);
Vue.component("AboutUsPage", require('./pages/AboutUsPage/index.vue').default);

Vue.component("LocaleChanger", require('./components/LocaleChanger.vue').default);
Vue.component("AppMenu", require('./components/layout/AppMenu.vue').default);

Vue.component("HomeFountain", require('./components/HomePage/HomeFountain.vue').default);
Vue.component("VideoDisclaimer", require('./components/TaigaPage/VideoDisclaimer.vue').default);
Vue.component("BottleMarquee", require('./components/TaigaPage/BottleMarquee.vue').default);
Vue.component("FontsSlide", require('./components/FountainsPage/FontsSlide.vue').default);
Vue.component("SlideImage", require('./components/FountainsPage/SlideImage.vue').default);


Vue.use(VueMeta);

Vue.use(VueGtm, {
  id: "GTM-W7XWDZ8",
  defer: false, // defaults to false. Script can be set to `defer` to increase page-load-time at the cost of less accurate results (in case visitor leaves before script is loaded, which is unlikely but possible)
  enabled: false, // defaults to true. Plugin can be disabled by setting this to false for Ex: enabled: !!GDPR_Cookie (optional)
  debug: true, // Whether or not display console logs debugs (optional)
  loadScript: true, // Whether or not to load the GTM Script (Helpful if you are including GTM manually, but need the dataLayer functionality in your components) (optional)
  trackOnNextTick: false, // Whether or not call trackView in Vue.nextTick
});

new Vue({
	i18n,
	router,
	render: h => h(App),
	mounted(){
		AOS.init()
	},
}).$mount('#app')
