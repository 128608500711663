<template>
 

    <div class="bottles-slider">
      <div class="position-relative marquee-container d-none d-sm-block">
        <div class="marquee marquee1 d-flex justify-content-around">
          <img src="../../assets/img/bottles.svg" alt="">
        </div>
        <div class="marquee marquee2 d-flex justify-content-around">
          <img src="../../assets/img/bottles.svg" alt="">
        </div>
      </div>
    </div>    
 
  
</template>

<script>
  

export default {
  name: 'BottleMarquee',
  props: {}
} 

</script>

<style lang="scss">

  .bottles-slider
  {
    height: 280px;
    width: 100%;
    
    position: relative;
    background-color: white;
    overflow: hidden;

    display: none;

    @include respond-above(md)
    {
      margin-top: 65px;
      margin-bottom: 65px;
      display: block;
    }

    @include respond-above(lg)
    {
      margin-bottom: 0;
    }

    @include respond-above(xl)
    {
      height: 360px;
    }    

    img{
      height: auto;
      width: calc(100vw - 45px);
    }

    .marquee-container {
      height: 30px;
      overflow: hidden;
      line-height: 30px;

      .marquee {
        top: 0;
        left: 100%;
        width: 100%;
        overflow: hidden;
        position: absolute;
        white-space: nowrap;
        animation: marquee 30s linear infinite;
      }

      .marquee1 {
        animation-delay: -15s;
      }

      .marquee2 {
        animation-delay: 0s;
      }

      b {
        padding-left: 10px;
      }
    }

    @keyframes marquee {
      0% {
        left: 100%;
      }
      100% {
        left: -100%
      }
    }
    
  }
  
  

</style>
