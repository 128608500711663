<template>
	<div id="about-us">
		
		<div class="text-container">
			<h2 v-html="$t('about_us.title')"></h2>
		</div>
		
	</div>
</template>

<script>
export default {

	name: 'AboutUsPage',
	props: {
		msg: String
	}

}

</script>

<style lang="scss" scoped>
	
	#about-us 
	{
		
		margin-top: 100px;
		position: relative;
		display: block;

		background-image: url('../../assets/img/about.webp');
		background-position: center;
		background-size: cover;
		
		width:100%;
		height: 320px;

		display: flex;
		flex-direction: column;  /* make main axis vertical */
		justify-content: center; /* center items vertically, in this case */
		align-items: center;     /* center items horizontally, in this case */

		@include respond-above(md)
		{
			height: 700px;
		}

		@include respond-above(xl)
		{
			background-image: url('../../assets/img/about_small.webp');
			background-position: center top;
			height: 860px;
			justify-content: start;
		}

		
		.text-container	
		{
			margin-top:30px;

			@include respond-above(md)
			{
				margin-top: 60px;
			}
				
			h2
			{
				font-size: 31px;
				line-height: 31px;
				font-weight:bold;
				color:white;
				text-shadow: 2px 2px 1px rgba(#000,0.5);


				@include respond-above(md)
				{
					font-size: 50px;
					line-height: 50px;
				}

			}
		}
		
	}

</style>