<template>
	
	<div id="fountain" ref="fountains">
		<div class="content-wrapper">
			<div class="content" v-on:scroll="horizontalSlider">
				<FontsSlide v-for="(slide, key) in slides" :ref="'slide_'+key" :title="slide.title" :text="slide.text" :slide_count_total="slides.length" :images="slide.images" v-bind:key="key" :slide_id="slide.id" :slide_count="key"></FontsSlide> 
			</div>
			<div class="slide-count mobile">  
				<div class="count" v-for="(slide,i) in slides" :key="i" v-bind:class="{'active': current_slide  == i }"></div>
			</div>
			
			<div class="display" alt="">
				<div class="row">
					<div class="column">
						<transition name="fade">
							<div  v-show="show_text" class="text-content-parent">
								
								<div ref="text_content">
									
								</div>
								<ul>
									<transition-group name="fade" tag="li" v-if="additional_texts.length > 0">
										<li v-for="additional_text in additional_texts" v-html="additional_text" :key="additional_text"></li>
									</transition-group>
								</ul>

							</div>
						</transition>	

						

						<div class="slide-count">  
							<a class="count" :href="'#slide_'+i" v-for="(slide,i) in slides" :key="i" v-bind:class="{'active': current_slide  == i }"></a>
						</div>

					</div><div class="column ">
						<div class="images-container">
							<transition name="fade">
								<img v-if="show_active_img" :src="active_img_url" alt="">
							</transition>
							<transition name="extra">
								<img class="extra_img" v-if="show_extra_active_img" :src="require('@/assets/img/' +extra_active_img_url+'')"  alt="">
							</transition>
							
						</div>
					</div>

				</div>
			</div>
	
		</div>
	</div>

</template>

<script>
	
export default { 
	name: 'FountainsPage',
	props: ['render_lang'],
	mounted:function(){
		this.updateSlide(0,0);
		this.reloadSlideInfo();

		// let vm =this;

		const el = document.querySelector(".display")

		const observer = new IntersectionObserver( 

			function(e){
				if(e[0].intersectionRatio < 1)
				{
					console.log("NO Posaria sticky");
					// vm.pageScroll(true)
				}else{
					console.log("SÍ posaria?");
					// vm.pageScroll()
				}
			},
			// ([e]) => e.target.classList.toggle("is-pinned", e.intersectionRatio < 1),
			{ threshold: [1] }
		);

		observer.observe(el);

	},
	watch: {
		current_slide(new_slide, old_slide)
		{
			// console.log(old_slide+" vs new => "+new_slide);

			if((old_slide < new_slide)&&(new_slide == 1))
			{
				this.pageScroll()
			}else{
				this.pageScroll(true);
			}
		},
		render_lang()
		{
			this.reloadSlideInfo();
		}
	},
	computed: {
		slides: function()
		{
			return [
				
				{ 
					id:0,
					title: this.$t('les_fonts.slide_2.title'), 
					text: this.$t('les_fonts.slide_2.description'), 
					images:
					[
						{
							"id":0,
							"src":"slide_2_1.png",
							"text":""
						}
					] 
				},
				{ 
					id:1,
					title: this.$t('les_fonts.slide_1.title'), 
					text: this.$t('les_fonts.slide_1.description'),  
					images:
					[
						{
							"id":0,
							"src":"slide_1_1.png",
							"text": this.$t('les_fonts.slide_1.sections.section_1'),
							"extra_src":"extra_slide_1_1.png"
						},
						{
							"id":1,
							"src":"slide_1_1.png",
							"text": this.$t('les_fonts.slide_1.sections.section_2'),
							"extra_src":"extra_slide_1_2.png"
						},
						{
							"id":2,
							"src":"slide_1_1.png",
							"text": this.$t('les_fonts.slide_1.sections.section_3'),
							"extra_src":"extra_slide_1_3.png"
						},
						{
							"id":3,
							"src":"slide_1_1.png",
							"text": this.$t('les_fonts.slide_1.sections.section_4'),
							"extra_src":"extra_slide_1_4.png"
						},
						{
							"id":4,
							"src":"slide_1_1.png",
							"text": this.$t('les_fonts.slide_1.sections.section_5'),
							"extra_src":"extra_slide_1_5.png"
						}
					]
				}, 
				{ 
					id:2,
					title: this.$t('les_fonts.slide_3.title'), 
					text: this.$t('les_fonts.slide_3.description'), 
					images:
					[
						{
							"id":0,
							"src":"slide_3_1.png",
							"text":""
						}
					] 
				},
				{ 
					id:3,
					title: this.$t('les_fonts.slide_4.title'), 
					text: this.$t('les_fonts.slide_4.description'), 
					images:
					[
						{
							"id":0,
							"src":"slide_4_1.png",
							"text":""
						}
					] 
				}
			];
		}
	},
	data: function() {
		return  {
			scroll_timeout: null,
			additional_texts:[],
			show_active_img: true,
			show_extra_active_img: true,
			show_text: true,
			current_slide: 0,
			active_img_url:'',
			extra_active_img_url: 'extra_slide_1_1.png',
			pinned_content: false
		}
	},
	methods: {

		reloadSlideInfo: function()
		{
			this.slides = [
				
				{ 
					id:0,
					title: this.$t('les_fonts.slide_2.title'), 
					text: this.$t('les_fonts.slide_2.description'), 
					images:
					[
						{
							"id":0,
							"src":"slide_2_1.png",
							"text":""
						}
					] 
				},
				{ 
					id:1,
					title: this.$t('les_fonts.slide_1.title'), 
					text: this.$t('les_fonts.slide_1.description'),  
					images:
					[
						{
							"id":0,
							"src":"slide_1_1.png",
							"text": this.$t('les_fonts.slide_1.sections.section_1'),
							"extra_src":"extra_slide_1_1.png"
						},
						{
							"id":1,
							"src":"slide_1_1.png",
							"text": this.$t('les_fonts.slide_1.sections.section_2'),
							"extra_src":"extra_slide_1_2.png"
						},
						{
							"id":2,
							"src":"slide_1_1.png",
							"text": this.$t('les_fonts.slide_1.sections.section_3'),
							"extra_src":"extra_slide_1_3.png"
						},
						{
							"id":3,
							"src":"slide_1_1.png",
							"text": this.$t('les_fonts.slide_1.sections.section_4'),
							"extra_src":"extra_slide_1_4.png"
						},
						{
							"id":4,
							"src":"slide_1_1.png",
							"text": this.$t('les_fonts.slide_1.sections.section_5'),
							"extra_src":"extra_slide_1_5.png"
						}
					]
				}, 
				{ 
					id:2,
					title: this.$t('les_fonts.slide_3.title'), 
					text: this.$t('les_fonts.slide_3.description'), 
					images:
					[
						{
							"id":0,
							"src":"slide_3_1.png",
							"text":""
						}
					] 
				},
				{ 
					id:3,
					title: this.$t('les_fonts.slide_4.title'), 
					text: this.$t('les_fonts.slide_4.description'), 
					images:
					[
						{
							"id":0,
							"src":"slide_4_1.png",
							"text":""
						}
					] 
				}
			];
		},

		pageScroll: function(stop = false) {

			if(!this.isMobile())
			{
				window.scrollBy(0,10);
				let vm = this;
				if(!stop)
				{
					this.scroll_timeout = setTimeout(function(){vm.pageScroll(stop);},1);	
				}else{
					clearTimeout(this.scroll_timeout);
				}	
			}
		},
		horizontalSlider: function(e)
		{
			
			var slide_offset = (e.target.scrollWidth / this.slides.length);
			this.current_slide = Math.round(e.target.scrollLeft / slide_offset);
		},

		isMobile: function() {

			if(this.$refs.fountains.clientWidth <= 768)
			{
				return true;
			}else{
				return false;	
			}
			
		},

		updateSlide: function(image_id,slide_id)
		{			

			if(!this.isMobile())
			{
				this.slideShow();

				this.current_slide = slide_id;

				if(this.$refs.text_content.innerHTML != this.$refs['slide_'+slide_id][0].$refs.text_content.innerHTML)
				{

					this.additional_texts = [];

					this.show_text = false;


					setTimeout(() => this.$refs.text_content.innerHTML = this.$refs['slide_'+slide_id][0].$refs.text_content.innerHTML , 500);
					
					setTimeout(() => this.show_text = true, 500);

				}

				
				var image_src = require('@/assets/img/'+this.slides[slide_id].images[image_id].src);

				for(var i = 0; i < this.slides[slide_id].images.length; i++)
				{
					if(i <= image_id)
					{
						if(!this.additional_texts.includes(this.slides[slide_id].images[i].text))
						{
							this.additional_texts.push(this.slides[slide_id].images[i].text);  
						}

					}else{	

						let slides = this.slides;

						this.additional_texts = this.additional_texts.filter(function(e) { return e !== slides[slide_id].images[i].text });
					}


				}

				if(typeof this.slides[slide_id].images[image_id].extra_src != "undefined")
				{

					if(this.extra_active_img_url != this.slides[slide_id].images[image_id].extra_src)
					{
						this.show_extra_active_img = true;
						this.extra_active_img_url = this.slides[slide_id].images[image_id].extra_src;
						
					}

				}else{
					this.show_extra_active_img = false;
				}


				if(image_src != this.active_img_url)
				{
					this.show_active_img = false;

					setTimeout(() => this.active_img_url = image_src, 500);
					
					setTimeout(() => this.show_active_img = true, 500);

					
				}

				if(typeof this.slides[slide_id].images[image_id].extra_src != "undefined")
				{
					this.show_extra_active_img = true;
				}
			}else{
				// console.log("not updating");
			}		
			
		},

		slideShow: function()
		{
			// console.log("entro a slideSHow");
		},

		navigateTo: function (slide)
		{
			alert(slide);	
		}
	}

}

</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
	
	#fountain
	{
		

		position: relative;
		display: block;

		.content
		{	
			@include respond-below(md)
			{
				display: flex;
				overflow-x: scroll;
			}

		}
	
        .separator
        {
			
			position: absolute;
			width:2px;
			background-color: $primary-color;
			height: calc(100% - 40px);
			left: calc(50% - 2px);
			margin-bottom: 40px;		
			z-index:888;
			display: none;

			@include respond-above(md)
			{
				display: block;
			}

		}

		@include respond-above(md)
		{
			&::before
			{
				content:"";
				
				position: -webkit-sticky;
				position: sticky;
				width: 2px;
				height: 40px;
				background-color: white;
				top: 0;
				left: calc(50% - 2px);
				z-index:889;
				display: block;
			}

			&::after
			{
				content:"";
				
				position: -webkit-sticky;
				position: sticky;
				width: 2px;
				height: 40px;
				background-color: white;
				bottom: 0px;
				left: calc(50% - 2px);
				z-index:889;
				display: block;
			}

		}


		.display
		{

			
			position: -webkit-sticky;
			position: sticky;
			height:100vh;
			width: 100%;
			bottom:0;
			display: none;
			background-color: white;

			@include respond-above(md)
			{
				display: inline-block;
			}
			
			.row
			{
				width: 100%;
				display: flex;

				.column
				{
					width:50%;
					display: inline-block;
					position: relative;
					height: 100vh;

					.text-content-parent
					{
						width:100%;
						height: 100vh;
						height: 100vh;
						position: absolute;
						top:30vh;

						ul{

							width: 100%;
							padding: 0;
							list-style: none;
							position: absolute;
							display: block;

							li
							{
								margin-left: 0;
								padding-left: 0;
								font-weight: 400;
								padding: 0;
								line-height: 20px;
								font-size: 18px;
								color: $primary-color;
								max-width: 500px;
								margin:0 auto;
							}

						}

						h4{

							font-weight: bold;
							margin: 0 auto 17px auto;
							line-height: 40px;
							font-size: 18px;
							color: $secondary-color;

							border-radius: 50%;
							width: 35px;
							height: 35px;
							padding: 8px;

							background: $primary-color;


							text-align: center;

						}

						h3{
							font-weight: bold;
							margin: 0 0 19px 0;
							padding: 0;
							line-height: 35px;
							font-size: 35px;
							color: $primary-color;
						}

						p{
							font-weight: 400;
							padding: 0;
							line-height: 20px;
							font-size: 18px;
							color: $primary-color;
							max-width: 500px;
							margin:0 auto;

							a
							{
								color: $primary-color;
								z-index: 999999;
							}
						}


					}

					.images-container
					{
						display: flex;
						align-items: center;
						justify-content: center;
						position: relative;
						height: 100vh;

						img
						{
							height: auto;
							max-width: 100%;
							max-height:100vh;
							width: auto;
							margin:0 auto;
							position: relative;

							&.extra_img
							{
								position: absolute;
								width: auto;
								max-width: 100%;
								max-height:100vh;
								height: auto;
							}
						}
					}

					
				}
			}
		}

		.slide-count
		{
			position: absolute;
			bottom: 0px;
			padding-bottom:40px; 
			width: 50vw;
			left:50%;
			margin-left: -25vw;
			height: 20px;
			text-align: center;
			background-color: white;
			display: block;

			&.mobile{

				position: relative;
				margin-top: 10px;

				&::after
				{
					display: none;
				}

				@include respond-above(md)
				{
					display: none;
				}
			}

			&::after
			{
				content:"";
				position: absolute;
				top:-40px;
				display: block;
				width: 100%;
				background-color: white;
				height: 40px;

			}

			.count
			{
				width: 15px;
				height: 15px;
				display: inline-block;
				margin:0 10px;
				border-radius: 100%;
				border:1px solid $primary-color;

				&.active
				{
					background-color: $primary-color;
				}

			}
		}

		.fade-enter-active, .fade-leave-active {
			transition: opacity .5s;
		}
		.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
			@include opacity(0);
		}

		.extra-enter-active, .extra-leave-active {
			transition: opacity .5s;
			
		}

		.extra-enter-active{
			transition-delay: .5s;
		}

		.extra-enter, .extra-leave-to /* .fade-leave-active below version 2.1.8 */ {
			@include opacity(0);
		}

	}

</style>
