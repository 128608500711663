<template>

    <div class="img-fountain" sticky-container>
      <img class="image" ref="image" v-sticky on-stick="checkImage" :src="imageRoute" alt="">
      <img class="image-mobile" :src="imageRoute" alt="">
    </div>
  
  
</template>

<script>

import VueStickyDirective from 'vue-sticky-directive';

export default {
  name: 'SlideImage',
  props: ['imageRoute','imageText','linked_slide_id','img_id'],
  directives: {
    "sticky": VueStickyDirective
  },
  methods: {
    checkImage: function(item)
    {
      if(item.sticked)
      {
        
        this.$refs.image.classList.add('sticked');
        this.$parent.updateSlide(this.img_id,this.linked_slide_id);
      }else{
        
        this.$refs.image.classList.remove('sticked');
      }
    }
  }
} 

</script>


<style lang="scss" scoped>

  .img-fountain
  {
    
    height: 100vh;
    width: 100%;
    position: relative;
    background-color: white;
    

    @include respond-below(md)
    {

      display: none;

      .image
      {
        display: none;
      }

      &:first-child
      {
        display: block;
        max-width: 100%;
        height: auto;
      }

    }
    
        

    @include respond-above(md)
    {

      
      height:250vh;    

      .image-mobile
      {
        display: none;
      }
      .image
      {
        display: initial;
      }
    }

    img
    { 

      top:0;
      left:50%;
      max-width: 100%;
      

      @include respond-below(md)
      {
        max-width: 100%;
        max-height: 300px;
        margin-top: 20px;
      }
    

      @include respond-above(md)
      {
        margin-top: 0px;
        width:auto!important;
        height: 100vh;
        @include opacity(0);
      }
     
    }
  }

</style>
