import Vue from 'vue'
import VueRouter from 'vue-router'
import { Trans } from '@/plugins/Translation'


function load(component) {
  // '@' is aliased to src/components
  return () => import(`@/views/${component}.vue`)
}

Vue.use(VueRouter)

const routes = [{
    path: '/:locale',
    component: {
      template: "<router-view></router-view>"
    },
    beforeEnter: Trans.routeMiddleware,
    children: [{
        path: '/',
        name: 'Main',
        component: load('Main')
      },
      {
        path: 'legal',
        name: 'Legal',
        component: load('Legal')
      },
      {
        path: 'cookies',
        name: 'Cookies',
        component: load('Cookies')
      },
      {
        path: 'privacy',
        name: 'Privacy',
        component: load('Privacy')
      },
      {
        path: 'conditions',
        name: 'Conditions',
        component: load('Conditions')
      }]
  },
  { // Redirect to fallback
    path: '*',
    redirect() {
      return Trans.defaultLocale;
    }
  }
]



const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior (to, from, savedPosition) {
    if (to.hash) {
      return { selector: to.hash }
    } else if (savedPosition) {
      return savedPosition;
    } else {
      return { x: 0, y: 0 }
    }
  }
})

export default router
