<template>

	<div id="menu">

		<div class="topbar">
			<router-link @click.native ="scrollToTop()" :to="$i18nRoute({ name: 'Main' })"><img class="logo" src="../../assets/img/logo.svg" alt=""></router-link>
		</div>
			<svg class="ham hamRotate ham8"	viewBox="0 0 100 100" width="80" onclick="this.classList.toggle('active')">
				<path class="line top" d="m 30,33 h 40 c 3.722839,0 7.5,3.126468 7.5,8.578427 0,5.451959 -2.727029,8.421573 -7.5,8.421573 h -20" />
				<path class="line middle" d="m 30,50 h 40" />
				<path class="line bottom" d="m 70,67 h -40 c 0,0 -7.5,-0.802118 -7.5,-8.365747 0,-7.563629 7.5,-8.634253 7.5,-8.634253 h 20" />
			</svg>	
		
		

		<div class="content">
			<router-link @click.native ="closeMenu()" :to="$i18nRoute({ name: 'Main' })"><img class="logo" src="../../assets/img/logo_light.svg" alt=""></router-link>
			<div id="nav">
				<router-link @click.native ="closeMenu()" to="/#taiga">{{$t('menu.problem')}}</router-link>
				<router-link @click.native ="closeMenu()" to="/#fountain">{{$t('menu.fonts')}}</router-link>
				<router-link @click.native ="closeMenu()" to="/#about-us">{{$t('menu.about')}}</router-link>
				<router-link @click.native ="closeMenu()" to="/#contact">{{$t('menu.contact')}}</router-link>
			</div>
			<div class="footer">
				<div class="info">
					<a href="mailto: hola@taigaproject.com">hola@taigaproject.com</a>
					<br>
					<a href="tel: +34696944464">(+34) 696 944 464</a>
				</div>
				<div class="language-selector">
					<LocaleChanger/>
				</div>
				<div class="legal-links">
					<router-link @click.native ="closeMenu()" :to="$i18nRoute({ name: 'Conditions' })">{{ $t('menu.conditions')}}</router-link>
					<router-link @click.native ="closeMenu()" :to="$i18nRoute({ name: 'Privacy' })">{{ $t('menu.privacy')}}</router-link>
					<br>
					<router-link @click.native ="closeMenu()" :to="$i18nRoute({ name: 'Legal' })">{{ $t('menu.legal')}}</router-link>
					<router-link @click.native ="closeMenu()" :to="$i18nRoute({ name: 'Cookies' })">{{ $t('menu.cookies')}}</router-link>
				</div>
			</div>
		</div>
	</div>

</template>

<script>
	import LocaleChanger from '@/components/LocaleChanger.vue'

	export default {
		name: 'AppMenu',
		components: {
			LocaleChanger
		},
		methods: {
			closeMenu: function()
			{
				document.querySelector('.ham').classList.remove("active");
			},
			scrollToTop() {
				window.scrollTo(0,0);
			}
		}
	}

</script>

<style  lang="scss" scoped>

#menu
{
	position: fixed;
	top:0;
	left:0;
	width:100vw;
	height: 100vh;
	z-index:999;
	pointer-events:none;

	.topbar
	{
		pointer-events:all;

		@include respond-below(md)
		{
			display: block;
			position: relative;
			height: 80px;
			background-color: white;
			border-bottom: 2px solid $primary-color;
		}
		
	}


	.logo
	{
		position: absolute;
		left:20px;
		top:17px;
		cursor: pointer;
		z-index: 99;
		pointer-events:all;
	}

	.content
	{
		display: none;
		position: relative;
		top:-82px;
		background-color: $primary-color;
		width:100%;
		height: 100%;

		@include respond-above(md)
		{
			top: 0;
		}

		.logo
		{
			@include respond-above(md)
			{
				text-align: center;
				margin: 0 auto;
				position: relative;
				left: initial;
			}
		
		}

	}

	#nav
	{
		align-items: center;
		display: flex;
		justify-content: center;
		flex-direction: column;
		position: relative;
		height: calc(100% - 350px);

		@include respond-above(md)
		{
			height: calc(100% - 170px);
		}

		a
		{
			display: block;
			text-decoration: none;
			font-size: 30px;
			font-weight: lighter;
			line-height: 30px;
			margin-top:10px;
			margin-bottom: 10px;
			color: $c-element;

			@include respond-above(md)
			{
				font-size: 70px;
				line-height: 70px;
				margin-top:15px;
				margin-bottom: 15px;
			}

			&:hover
			{
				color: $c-element-hover;
			}
			
		}
	}

	.footer
	{
		height: auto;
		width: calc(100% - 40px);
		padding: 20px;
		display: block;
		position: absolute;
		bottom: 20px;

		display: flex;

		flex-direction: column;
		align-items: flex-start;

		@include respond-above(md)
		{
			flex-direction: row;
			align-items: flex-end;
		}

		.info
		{

			font-weight: normal;
			font-size: 20px;
			line-height: 40px;
			width: 100%;
			margin: 0;
			padding: 0;
			display: inline-block;

			@include respond-above(md)
			{
				font-size: 25px;
				width: 40%;
				line-height: 30px;
			}

			a
			{
				color: $c-element;
				text-decoration: none;
				display: block;

				@include respond-above(md)
				{
					display: inline-block;

				}

				&:first-child
				{
					margin-right: 20px;
					margin-left: 10px;
				}

				&:hover
				{
					color: $c-element-hover;
				}
			}			
		}

		.language-selector
		{
			font-weight: normal;
			font-size: 20px;
			line-height: 40px;
			width: 100%;
			margin: 0;
			padding: 0;
			display: inline-block;
			

			@include respond-above(md)
			{	
				font-size: 25px;
				line-height: 30px;
				width: 20%;
			}
		}	

		.legal-links
		{
			font-weight: normal;
			font-size: 15px;
			line-height: 40px;
			width: 100%;
			margin: 0;
			padding: 0;
			display: inline-block;
			

			@include respond-above(md)
			{
				
				font-size: 20px;
				width: 40%;
				line-height: 30px;
			}

			br
			{
				display: none;

				@include respond-above(lg)
				{
					display: block;
				}
			}	

			a
			{
				color: $c-element;
				text-decoration: none;
				text-transform: uppercase;

				

				@include respond-below(md)
				{
					display: block;
				}

				@include respond-above(md)
				{
					display: inline-block;
				}	

				margin-right: 10px;
				margin-left: 10px;
				

				&:hover
				{
					color: $c-element-hover;
				}
			}
		}
	}

	.ham 
	{

		position: absolute;
		right:3px;
		top:0;
		z-index:9999;
		pointer-events:all;
		cursor: pointer;
		-webkit-tap-highlight-color: transparent;
		transition: transform 400ms;
		-moz-user-select: none;
		-webkit-user-select: none;
		-ms-user-select: none;
		user-select: none;

		.top 
		{
			stroke-dasharray: 40 160;
			transform: translateY(3px);
		}

		.middle 
		{
			stroke-dasharray: 40 142;
			transform-origin: 50%;
			transition: transform 400ms;
		}

		.bottom 
		{
			stroke-dasharray: 40 85;
			transform-origin: 50%;
			transition: transform 400ms, stroke-dashoffset 400ms;
			transform: translateY(-3px);
		}

		&.active
		{
			.top 
			{
				stroke-dashoffset: -64px;
				transform: translateY(0px);
			}

			.middle 
			{
				transform: rotate(90deg);
			}

			.bottom 
			{
				stroke-dashoffset: -64px;
				transform: translateY(0px);
			}

			&+.content
			{
				display: block;
				pointer-events:all;
			}

			.line
			{
				stroke:$secondary-color;
			}

		}

	}

	.hamRotate.active {
		transform: rotate(45deg);
	}

	.hamRotate180.active {
		transform: rotate(180deg);
	}

	.line {
		fill:none;
		transition: stroke-dasharray 400ms, stroke-dashoffset 400ms;
		stroke:$primary-color;
		stroke-width:5.5;
		stroke-linecap:round;
	}
}

</style>
