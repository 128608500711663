<template>
	<div id="taiga">
		<div class="row first">
			<div class="column">
				<h4 v-html="$t('taiga.slide_1.title')"></h4>
				<p v-html="$t('taiga.slide_1.text')"></p>
			</div>
			<div class="column">
				<img src="../../assets/img/problema_0.png" alt="">
			</div>
		</div>
		<div class="row second">
			<div class="column">
				<img src="../../assets/img/problema_1.png" alt="">
			</div>
			<div class="column">
				<h4 v-html="$t('taiga.slide_2.title')"></h4>
				<p v-html="$t('taiga.slide_2.text')"></p>
			</div>
		</div>

		<VideoDisclaimer></VideoDisclaimer>
	
		<BottleMarquee></BottleMarquee>
		
	</div>
</template>

<script>



export default {
	name: 'TaigaPage',
	props: {
		msg: String
	}
}
</script>


<style lang="scss">

	#taiga
	{	

		@include respond-above(md)
		{
			
		}

		.row
		{
			width: 100%;
			max-width: 1133px;
			margin:0 auto;
			display: flex;
			position: relative;
			align-items: center;
			justify-content: center;


			@include respond-below(md)
			{
				flex-direction:column;
				&.second
				{
					.column:first-child
					{
						order:2;
					}
				}
			}
		}

		.column	
		{

			padding: 0 22px;

			@include respond-above(md)
			{
				padding:0;
			}

			@include respond-above(md)
			{
				display: inline-block;
				width:calc(50% - 100px);
				padding:50px;
				vertical-align: middle;
			}

			h4
			{
				font-style: normal;
				font-weight: bold;
				font-size: 25px;
				line-height: 25px;
				color:$primary-color;
				margin:0;

				@include respond-above(md)
				{
					font-size: 35px;
					line-height: 35px;
				}
			}

			p
			{
				font-style: normal;
				font-weight: regular;
				font-size: 18px;
				line-height: 20px;
				color:$primary-color;
			}

			img{
			
				width:500px;
				height: 375px;
				max-width: 100%;

				margin-bottom: 40px;
				@include respond-above(md)
				{
					margin-bottom: 0;
				}
			}
		}

	}
	
	

</style>
