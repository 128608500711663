<template>
 
  <div>
    <video-bg class="bg-video" :sources="[require('@/assets/video/OP_3.mp4')]">

      <div class="content">
        <h4>{{ $t('video.title')}} </h4>
        <p>{{ $t('video.description')}}</p>  
      </div>

    </video-bg>
    <div class="bg-img">
      <div class="content">
        <h4>{{ $t('video.title')}} </h4>
        <p>{{ $t('video.description')}}</p>  
      </div>
    </div>
  </div>
  
</template>

<script>
  
  import VideoBg from 'vue-videobg';

  export default {
    name: 'VideoDisclaimer',
    components: { VideoBg },
    props: {},
    methods:
    {
      isMobile: function() {

        if(/Android|webOS|iPhone|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) 
        {
          return true;
        }else {
          return false;
        }
      },
    }
  } 

</script>

<style lang="scss">
  
  

  .bg-video, .bg-img
  {
    max-height: 455px;
    width: 100%;
    position: relative;
    text-align: center;

    @include respond-above(md)
    {
      max-height: 655px;
      margin-top: 160px;
    }

    .VideoBg__content
    {
      display: table; 

      .content
      {
        display: table-cell;
        vertical-align: middle;


        h4
        {
          margin:0;
          padding: 0 22px;
          font-size: 35px;
          font-weight: bold;
          line-height: 35px;
          margin-bottom: 20px;
        }

        p
        {
          font-size: 23px;
          line-height: 26px;
          font-weight: 400;
          max-width: 800px;
          margin:0 auto;
          padding: 0 22px;
        }
      }
    }

    *{
      color:white;
      text-align: center;
      vertical-align: middle;
      text-shadow: 2px 2px 5px #000;
    }
  }
  
  .bg-video
  {
    display: none;
    @include respond-above(md)
    {
      display: block;
    }
  }

  .bg-img
  {

    background-image: url('../../assets/img/video_bg.png');
    display: block;

    .content
    {
      padding:50px;
    }

    @include respond-above(md)
    {
      display: none;
    }
  }

</style>
