<template>
  <div class="home-fountain">

    <div class="fountain-container" ref="fountainContainer">
      <div ref="fountain" :style="cssProps" class="fountain-img"></div>
      <img class="fountain-img-mobile" src="../../assets/img/mobile_fountain.jpg" alt="">

      <div v-html="$t('home.right')" data-aos="fade-left" data-aos-once="true" class="floating-text text-right"></div>

      <div v-html="$t('home.left')" data-aos="fade-right" data-aos-once="true" class="floating-text text-left"></div>    
    </div>
    

  </div>
  
</template>

<script>
export default {
  name: 'HomeFountain',
  props: {
    msg: String
  },
  data: function() {
    return  {
      sprite_position: 455,
      sprite_width: 8190,
      sprite_division_width: 455,
      sprite_height: 2130,
      sprite_steps: 18
    }
  },
  computed: {
    cssProps() {
      return {
        '--sprite-position': this.sprite_position + "px",
        '--sprite-width': this.sprite_width + "px",
        '--sprite-height': this.sprite_height + "px",
        '--sprite-division-width': this.sprite_division_width + "px"
      }
    }
  },
  created () {
    window.addEventListener('scroll', this.handleScroll);
  },
  destroyed () {
    window.removeEventListener('scroll', this.handleScroll);
  },
  methods: {
    handleScroll () {


      let step_h = Math.round(this.$refs.fountainContainer.clientHeight / this.sprite_steps );


        let step_mod =  Math.round(window.top.scrollY / step_h);

        if(window.top.scrollY < step_h)
        {
          this.sprite_position = 455;
        }
        else if(step_mod <= this.sprite_steps)
        {
          this.sprite_position = step_mod * this.sprite_division_width;
        }else{
          this.sprite_position = this.sprite_width;
          
        }

    }
  }
}
</script>

<style scoped lang="scss">
  
  .home-fountain  
  {
    display: block;
    position: relative;

    .fountain-container
    {
      height: auto;
    }

    .fountain-img-mobile
    {
      width: 200px;
      max-width: calc(100% - 100px);
      display: block;
      height: auto;
      margin:50px auto;
      position: relative;

      @include respond-above(sm)
      {
        display: none;
      }
    }

    .fountain-img
    {
      
      display: none;

      @include respond-above(sm)
      {
        position: relative;
        top: 0;
        margin:0 auto;
        display: block;
        background-image: url('../../assets/img/sprite_fountain.jpg');
        background-position: 0;
        background-position: var(--sprite-position);
        width: var(--sprite-division-width);
        background-size: var(--sprite-width);
        height: var(--sprite-height);
      }

    }

    .floating-text
    {
      display: none;
      position: absolute;
      top:0;
      font-style: normal;
      font-weight: bold;
      font-size: 50px;
      line-height: 50px;
      color:$primary-color;
      text-align: center;

      @include respond-above(md)
      {
        display: block; 
        max-width: 413px;
      }

      @include respond-above(lg)
      {
        max-width: 413px;
      }

      @include respond-above(xl)
      {
        max-width: 533px;
      }

      &.text-right
      {
        
        left:50%;

        @include respond-above(md)
        {
          padding-right:40px;
          margin-left: 190px;
          top: 780px;
        }

        @include respond-above(lg)
        {
          margin-left: 290px;
          padding-right:100px;
          top: 780px;
        }

        @include respond-above(xl)
        {
          top: 780px;
        }

      }

      &.text-left
      {

        padding-left: 20px;
        right:50%;
        
        
        @include respond-above(md)
        {
          padding-left: 40px;
          top: 1400px;  
          margin-right: 160px;
        }

        @include respond-above(lg)
        {
          padding-left:100px;
          top:1300px;
          margin-right: 300px;
        }

        @include respond-above(xl)
        {

          top: 1300px;
        } 
      }
    }

  }
  


</style>
