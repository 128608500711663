<template>
	<div id="contact">
		
		<div class="row">
			<div class="column">
				<img src="../../assets/img/contact.jpg" alt="">
			</div><div class="column">
				<h2 v-html="$t('contact.title')"></h2>
				<div class="link-group">
					<a :href="'mailto:'+$t('contact.email')">{{ $t('contact.email')}}</a>
					<a :href="$t('contact.phone')">{{ $t('contact.phone')}}</a>	
				</div>

				<div class="legal-links">
					<router-link :to="$i18nRoute({ name: 'Conditions' })">{{ $t('menu.conditions')}}</router-link>
					<router-link :to="$i18nRoute({ name: 'Privacy' })">{{ $t('menu.privacy')}}</router-link>
					<br>
					<router-link :to="$i18nRoute({ name: 'Legal' })">{{ $t('menu.legal')}}</router-link>
					<router-link :to="$i18nRoute({ name: 'Cookies' })">{{ $t('menu.cookies')}}</router-link>
				</div>
				
			</div>
		</div>
	</div>
</template>

<script>
export default {

	name: 'ContactPage',
	props: {
		msg: String
	}

}

</script>

<style lang="scss" scoped>
	
	#contact
	{

		margin-top: 100px;
		position: relative;
		display: block;
		padding:25px;


		.row	
		{
			display: flex;
			
			flex-direction:column;
			justify-content: center;
			
			@include respond-below(md)		
			{
				align-items: center;
			}

			@include respond-above(md)				
			{
				flex-direction:row;
			}

			& > * { 
				flex: 1; 
			}

			.column
			{
					
				position: relative;

				img{
					width:auto;
					height: 755px;
					max-width: 100%;
					display: none;

					@include respond-above(md)
					{
						display: initial;
					}
				}

				h2
				{
					font-size: 31px;
					line-height: 31px;
					font-weight:bold;
					color:$primary-color;


					@include respond-above(md)
					{
						font-size: 50px;
						line-height: 50px;
					}

				}

				.link-group
				{
					margin-top: 200px;

					a{
						font-weight: normal;
						font-size: 35px;
						line-height: 42px;
						color:$primary-color;
						display: block;
						text-decoration: none;
						
					}
				}

				.legal-links
				{
					position: absolute;
					bottom: 0px;
					text-align: center;
					margin: 0 auto;
					width:100%;

					@include respond-below(md)
					{
						display: none;
					}

					a{
						text-decoration: none;
						margin: 0 20px;
						font-size: 18px;
						line-height: 24px;
						color:$primary-color;
						text-transform: uppercase;

						&:hover
						{
							color: $c-element-hover;
						}
					}

					br
					{
						display: none;

						@include respond-above(lg)
						{
							display: block;
						}
					}	
				}
				
				
			}
		}
		
	}

</style>