<template>

	<div id="home">

		<h3 class="slogan" v-html="$t('home.slogan')"></h3>
		<h2 class="disclaimer" v-html="$t('home.disclaimer')"></h2>
		
		<HomeFountain></HomeFountain>
		

	</div>

</template>

<script>


import HomeFountain from "@/components/HomePage/HomeFountain.vue";


export default {
	name: 'HomePage',
	components:{ 
		HomeFountain
	},
	props: {
		msg: String
	}
}
</script>

<style scoped lang="scss">
	
	#home
	{

		position: relative;
		display: block;

		.disclaimer
		{
			font-style: normal;
			font-weight: bold;
			font-size: 31px;
			line-height: 31px;
			color:$primary-color;
			margin:149px 0 0 0;
			padding: 0 22px;
			

			@include respond-above(md)
			{
				padding:0;
				font-size: 50px;
				line-height: 50px;
				position: relative;
				z-index: 99;
			}
		}

		.slogan
		{
			font-style: normal;
			font-weight: 400;
			font-size: 25px;
			line-height: 25px;
			color:$primary-color;
			margin:35px 0 0px 0;


			display: none;

			@include respond-above(md)
			{
				display: block;
				
			}

		}
	}

	

</style>
