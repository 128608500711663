<template>

  <div class="slide" :id="'slide_'+slide_id" :style="cssProps" v-bind:class="{'last': slide_count_total == slide_count }" >
    <div class="column left-column" >
      <div class="text-content" ref="text_content" >
        <div  >
          <h4 v-html="slide_count + 1"></h4>
          <h3 v-html="title"></h3>
          <p v-html="text"></p>

          

        </div>
         

      </div>
    </div><div class="column right-column">

      <SlideImage v-for="(image, image_key) in images" v-bind:key="image_key" :linked_slide_id="slide_count" :img_id="image.id" :imageText="image.text" :imageRoute="require('@/assets/img/' + image.src + '')"></SlideImage>

    </div>
  </div>
  
</template>

<script>

export default {
  name: 'FontsSlide',
  props: ['title','text','images','slide_count','slide_count_total','slide_id'],
  data: function() {
    return  {
      
    }

  },
  computed: {
    cssProps() {
      return {
        '--column-height': (this.images.length * 250) + "vh",
      }
    }
  },
  methods:{


    updateBullets: function(display_bullets){
      if(display_bullets)
      {
      this.$parent.activateBullets(this.slide_count);  
      }else{
      this.$parent.deactivateBullets();  
      }
    },

    updateSlide: function(image_id,slide_id)
    {
      
      this.$parent.updateSlide(image_id,slide_id);
    }
  }
} 

</script>

<style lang="scss" scoped>

  .slide
  {

    margin-top: 50px;
    min-width:100vw;

    @include respond-above(md)
    { 
      display: flex;
      margin-top: 0;
      min-width: auto;
    }

    .fade-enter-active, .fade-leave-active {
      transition: opacity .3s;
    }
    .fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
      @include opacity(0);
    }

    &.last
    {
      .right-column
      {
        max-height: 100vh;
      }

    }


    a{
      color: $primary-color;
    }
    .column
      {
        height: auto;
        padding:0 22px;

        @include respond-above(md)
        { 
          padding: 0;
          width:50%;  
          display: block;
          height: var(--column-height);
        }
      }

      .left-column
      {

        .text-content
        {

        @include respond-above(md)
        { 

          height: 100vh;
          position: -webkit-sticky;
          position: sticky;
          top:30vh;
        }
          

          h4{

            font-weight: bold;
            margin: 0 auto 17px auto;
            line-height: 40px;
            font-size: 18px;
            color: $secondary-color;

            border-radius: 50%;
            width: 35px;
            height: 35px;
            padding: 8px;

            background: $primary-color;


            text-align: center;

          }

          h3{
            font-weight: bold;
            margin: 0 0 19px 0;
            padding: 0;
            
            color: $primary-color;
            padding:0 22px;

            font-size: 25px;
            line-height: 25px;

            @include respond-above(md)
            {
              line-height: 35px;
              font-size: 35px;
            }

          }

          p{
            font-weight: 400;
            padding: 0;
            line-height: 20px;
            font-size: 18px;
            color: $primary-color;
            max-width: 500px;
            margin:0 auto;

          }
          
        }
      }
      
      .right-column
      {
        


        @include respond-above(md)
        {
          display: block; 
        }

      }

}

</style>
