<template>
  <div id="app">
    <AppMenu />
    <router-view />
  </div>
</template>


<script>
  
  import AppMenu from '@/components/layout/AppMenu.vue'

  export default {
    name: 'App',
    components: {
      AppMenu
    },
    metaInfo() {
        return { 
            title: this.$t('meta.main.title'),
            meta: [
                { name: 'description', content:  this.$t('meta.main.description')},
                { property: 'og:title', content: this.$t('meta.main.title')},
                { property: 'og:site_name', content: 'Taiga'},
                {property: 'og:type', content: 'website'},    
                {name: 'robots', content: 'index,follow'}
            ]
        }
    }
  }
</script>

<style lang="scss">
  


  #app {
    font-family: Avenir, Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;
    color: #2c3e50;
  }

  #nav {
    
    padding: 30px;

    a {

      font-weight: bold;
      color: #2c3e50;

      &.router-link-exact-active {
        color: #42b983;
      }

    }

  }

  @font-face {
    font-family: "Weissenhof Grotesk";
    src: url('./assets/fonts/Weissenhof Grotesk/Indian Type Foundry - WeissenhofGrotesk-BoldItalic.otf') format("truetype");
    font-weight:bold;
    font-style: italic, oblique;
  }


  @font-face {
    font-family: "Weissenhof Grotesk";
    src: url('./assets/fonts/Weissenhof Grotesk/Indian Type Foundry - Weissenhof Grotesk Light Italic.otf') format("truetype");
    font-style: italic, oblique;
    font-weight:lighter;
  }

  @font-face {
    font-family: "Weissenhof Grotesk";
    src: url('./assets/fonts/Weissenhof Grotesk/Indian Type Foundry - WeissenhofGrotesk-MediumItalic.otf') format("truetype");
    font-weight:medium;
    font-style: italic, oblique;
  }

  @font-face {
    font-family: "Weissenhof Grotesk";
    src: url('./assets/fonts/Weissenhof Grotesk/Indian Type Foundry - WeissenhofGrotesk-RegularItalic.otf') format("truetype");
    font-style: italic, oblique;
  }

  @font-face {
    font-family: "Weissenhof Grotesk";
    src: url('./assets/fonts/Weissenhof Grotesk/Indian Type Foundry - WeissenhofGrotesk-Light.otf') format("truetype");
    font-weight:lighter;
  }

  @font-face {
    font-family: "Weissenhof Grotesk";
    src: url('./assets/fonts/Weissenhof Grotesk/Indian Type Foundry - WeissenhofGrotesk-Medium.otf') format("truetype");
    font-weight:medium;
  }

  @font-face {
    font-family: "Weissenhof Grotesk";
    src: url('./assets/fonts/Weissenhof Grotesk/Indian Type Foundry - WeissenhofGrotesk-Bold.otf') format("truetype");
    font-weight:bold;
  }

  @font-face {
    font-family: "Weissenhof Grotesk";
    src: url('./assets/fonts/Weissenhof Grotesk/Indian Type Foundry - WeissenhofGrotesk-Regular.otf') format("truetype");
    font-weight:400;
  }




  *{
    font-family: "Weissenhof Grotesk";
  }

</style>


