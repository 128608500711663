<template>
  <ul class="languages">
    <li v-for="locale in supportedLocales" :key="locale" @click="switchLocale(locale)" :ref="locale" :class="{active : (locale == currentLocale)}">
      {{ $t('menu.lang.' + locale) }}
    </li>
  </ul>
</template>

<script>

  import { Trans } from '@/plugins/Translation'

  export default {
    name: 'LocaleChanger',
    computed: {
      supportedLocales () {
        return Trans.supportedLocales
      },
      currentLocale () {
        return Trans.currentLocale
      }
    },
    data() {
      return {
        locales: process.env.VUE_APP_I18N_SUPPORTED_LOCALE.split(',')
      }
    },
    methods: {
      switchLocale(locale) {

        if (this.$i18n.locale !== locale) {
          
          const to = this.$router.resolve({ params: {locale} })
          return Trans.changeLocale(locale).then(() => {
            this.$router.push(to.location)
          })
        }

      }
    }
  }
</script>

<style scoped lang="scss">

  ul
  {
    margin: 0;
    padding: 0;

    li {
      color: $c-element;
      
      cursor: pointer;
      display: inline;

      &.active
      {
        color: $c-element-hover;
        cursor: default;
      }

      &:first-child, &:nth-child(2)
      {
        margin-right: 12px;
      }

      &:hover
      {
        color: $c-element-hover;
      }
    }
  }
  

</style>