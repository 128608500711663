import Vue from 'vue'
import VueI18n from 'vue-i18n'
import en from '@/locales/en.json'
import es from '@/locales/es.json'
import ca from '@/locales/ca.json'

Vue.use(VueI18n)

export const i18n = new VueI18n({
	seo: true,
	locale: process.env.VUE_APP_I18N_LOCALE || 'ca',
	fallbackLocale: process.env.VUE_APP_I18N_FALLBACK_LOCALE || 'ca',
	messages: { en, es, ca }
})
